import NavBar from "./NavBar";
import "./PageLayout.css"
import { NavBarItems } from "../models/Navigation";
import React from "react";
import imagePath from "../assets/globe.png";

type Props = {
    children?: React.ReactNode;
};

export const PageLayout: React.FC<Props> = ({children}) => {
    
    let items:NavBarItems = [
        { title: "Home", route: "home", authenticated: true }
    ] 

    return (
        <>
            <NavBar brandName="Journeeze" 
                imageSrcPath={imagePath} 
                navItems={items}/>
            {children}
        </>
    );
};