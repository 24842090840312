import { useState } from "react";
import "../App.css"
import { NavLink } from "react-router-dom";
import { NavBarItems } from "../models/Navigation";
import { LoginButton } from "../components/LoginButton";

interface NavBarProps {
  brandName: string;
  imageSrcPath: string;
  navItems: NavBarItems;
}

function NavBar({ brandName, imageSrcPath, navItems }: NavBarProps) {

  const [selectedIndex, setSelectedIndex] = useState(-1);

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-white shadow">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img
            src={imageSrcPath}
            width="60"
            height="60"
            className="d-inline-block align-center"
            alt=""
          />
          <span className="fw-bolder fs-4">{brandName}</span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse
         navbar-collapse"
        id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-md-1">
            {navItems.map((items, index) => (
              
              <li
                key={items.title}
                className="nav-item"
                onClick={() => {
                   setSelectedIndex(index); 
                  } }
              >
                <NavLink key={index} to={`/${items.route}`}
                  className={
                    selectedIndex == index
                      ? "nav-link active fw-bold"
                      : "nav-link"
                  }
                >
                  {items.title}
                </NavLink>
              </li>
            ))}
          </ul>
          <LoginButton/>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;