import Button from "@mui/material/Button";
import React from "react";

export const LoginButton = () => {
    return (
        <div>
            <Button>Login</Button>
        </div>
    )
}

export default LoginButton;