import imagePath from "../assets/globe.png";
import "./LandingPage.css"; 
export function LandingPage() {
    const centerDivStyle = {
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        height: '90vh'
      };

    return (
        <>
            <div style={centerDivStyle} className="container">
                <img
                    src={imagePath}
                    width="120"
                    height="120"
                    className="d-inline-block align-center"
                    alt="Journeeze Logo"/>
                <h1>Journeeze</h1>
                <p>Your personal trip planner</p>
                <br/>
                <p>coming soon...</p>
            </div>
        </>
    );
} 