import './App.css';
import { PageLayout } from './components/PageLayout';
import { Grid } from '@mui/material'
import { LandingPage } from './pages/LandingPage';
import { Routes, Route, useNavigate } from "react-router-dom";

function App() {
  useNavigate();
  
  return (
      <PageLayout>
          <Grid container justifyContent="center">
              <Pages />
          </Grid>
      </PageLayout>
  );
}

export default App;

function Pages() {
  return (
      <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />
      </Routes>
  );
}